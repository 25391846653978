import React from 'react';
import { graphql } from 'gatsby';

import { Layout, SEO } from 'src/components/shared';
import {
	Newspaper,
	JobsLayout,
	LayoutLeft,
	LayoutRight,
	JobPageIntro
} from 'src/components/jobs';

export default function IndexPage({
	data: {
		allMarkdownRemark: { edges },
		background
	}
}) {
	return (
		<Layout
			backgroundProps={{ fluid: background.childImageSharp.fluid }}
			withLogo
			variant="wide"
		>
			<SEO title="Jobs" />

			<JobsLayout variant="newspaper">
				<LayoutLeft>
					<JobPageIntro></JobPageIntro>
				</LayoutLeft>
				<LayoutRight style={{ perspective: 1000 }}>
					<Newspaper jobs={edges}></Newspaper>
				</LayoutRight>
			</JobsLayout>
		</Layout>
	);
}

export const pageQuery = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						date(formatString: "YYYYMMDD")
						position
						thumbnail {
							childImageSharp {
								fluid(quality: 90, maxWidth: 360) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		}
		background: file(relativePath: { eq: "background_jobs.png" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
